import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { fetchProfileTypes } from 'actions/profileTypesActions';
import { getAgencyId, getProfileTypes } from 'reducers';

import { Autocomplete } from '@material-ui/lab';
import { Accordion, AccordionSummary, Button, Divider, TextField, Typography } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExploreTalentsSearchBar from './ExploreTalentsSearchBar';
import MultiSelectList from './MultiSelectList';
import Switcher from 'components/Shared/Switcher';
import CategorySkills from 'components/Person/Profile/ProfileSkills/CategorySkills';
import SlimSelect from 'components/Shared/SlimSelect';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { getTalentRequestResetFiltersLink } from '../ExploreTalentsUtils';

import { SKILLS_CATEGORY } from 'constants/jobOpportunitiesConstants';
import { EXPERIENCE_ITEMS, PROFILE_TYPES, VETTED_SINCE_ITEMS } from '../ExploreTalentsConstants';

import './ExploreTalentsSearchFilter.scss';

const ExploreTalentsSearchFilter = ({
    agencyId,
    fetchSkillSuggestionsHandler,
    filters,
    handleAddSkill,
    handleRemoveSkill,
    handleFilterChange,
    resetFilters,
    handleSwitchChange,
    handleVettedSinceChange,
    handleSearchTermChange,
    profileTypes,
    fetchProfileTypes,
    searchTerm,
    specialityTerm,
    showFilters,
    setShowFilters,
    agencyRouteName,
}) => {
    const intl = useIntl();

    const [openDropdown, setOpenDropdown] = useState(false);
    const [maximumSalaryValue, setMaximumSalaryValue] = useState(filters.maximumSalary);
    const [language, setLanguage] = useState(filters.languages);
    const [location, setLocation] = useState(filters.location);

    useEffect(() => {
        fetchProfileTypes(agencyId)
    }, []);

    return (
        <div className={`explore-talents-search-filter-wrapper ${showFilters ? 'expanded' : ''}`}>
            <Accordion
                className="explore-talents-accordion"
                expanded={showFilters}
                onChange={() => setShowFilters((oldState) => !oldState)}
                elevation={2}
            >
                <AccordionSummary
                    className={`summary ${showFilters ? 'hidden' : 'visible'}`}
                    expandIcon={<KeyboardArrowDownIcon className="collapse-filters-icon" />}
                >
                    <FilterListIcon />
                    <Typography>{intl.formatMessage({ id: "explore-talents-accordion-text" })}</Typography>
                    <Link
                        onClick={(e) => e.stopPropagation()}
                        className="reset-filters-link"
                        to={getTalentRequestResetFiltersLink(agencyRouteName)}
                    >
                        {intl.formatMessage({ id: "reset-button-text" })}
                    </Link>
                </AccordionSummary>

                <div className="explore-talents-search-filter-container">
                    <div className="explore-talents-search-filter">
                        <div className="main-container">
                            <div className="main-sub-container">
                                <div className="search-container">
                                    <ExploreTalentsSearchBar
                                        searchTerm={searchTerm}
                                        handleChange={(e) => handleSearchTermChange("searchTerm", e.target.value)}
                                        clearInput={() => handleSearchTermChange('searchTerm', '')}
                                        searchByTerm={"name"}
                                    />
                                    <Button
                                        onClick={resetFilters}
                                        className="reset-explore-talents-filter-button"
                                        variant="outlined"
                                    >
                                        {intl.formatMessage({ id: "reset-button-text" }).toUpperCase()}
                                    </Button>
                                </div>
                                <div className="search-container">
                                    <Autocomplete
                                        className="select-tech-stack-autocomplete"
                                        disablePortal
                                        clearOnBlur={false}
                                        open={!!(openDropdown && specialityTerm)}
                                        options={profileTypes}
                                        noOptionsText={'No options'}
                                        getOptionLabel={(option) => option.title || ''}
                                        getOptionSelected={(option, value) => option.title === value.title}
                                        renderInput={(params) => {
                                            return (
                                                <>
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Search by speciality"
                                                        onChange={(event) => {
                                                            handleSearchTermChange("speciality", event.target.value);
                                                            setOpenDropdown(true);
                                                        }}
                                                    />
                                                </>
                                            )
                                        }}
                                        renderOption={(props) => {
                                            const title = props.title;
                                            return <div>{title}</div>
                                        }}
                                        onChange={(e, value) => {
                                            handleSearchTermChange("speciality", value.title);
                                        }}
                                        onClose={() => {
                                            setOpenDropdown(false);
                                        }}
                                        onFocus={() => setOpenDropdown(true)}
                                    />
                                    <Button
                                        onClick={resetFilters}
                                        className="reset-explore-talents-filter-button"
                                        variant="outlined"
                                    >
                                        {intl.formatMessage({ id: "reset-button-text" }).toUpperCase()}
                                    </Button>
                                </div>
                                <div className="switches-container">
                                    <Switcher
                                        className="switch-item"
                                        label={intl.formatMessage({ id: "search-in-full-cv" })}
                                        isChecked={filters.searchInFullCV}
                                        changeStatusHandler={handleSwitchChange('searchInFullCV')}
                                    />
                                    <Switcher
                                        className="switch-item"
                                        label={intl.formatMessage({ id: "show-available-only-text" })}
                                        isChecked={filters.availableOnly}
                                        changeStatusHandler={handleSwitchChange('availableOnly')}
                                    />
                                    <Switcher
                                        className="switch-item"
                                        label={intl.formatMessage({ id: "show-vetted-only-text" })}
                                        isChecked={filters.vettedOnly}
                                        changeStatusHandler={handleSwitchChange('vettedOnly')}
                                    />
                                    {filters.vettedOnly && (
                                        <div className="vetted-since-container">
                                            <span className="vetted-since-label">{intl.formatMessage({ id: "sort" })}:</span>
                                            <SlimSelect
                                                items={VETTED_SINCE_ITEMS}
                                                value={filters.vettedSince.value}
                                                name="vetted-since"
                                                onChangeHandler={handleVettedSinceChange}
                                                className="vetted-since-select"
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="sub-filters-container">
                                    <MultiSelectList
                                        className="sub-filter"
                                        subheaderText={intl.formatMessage({ id: "profile-type" })}
                                        items={PROFILE_TYPES}
                                        onChangeHandler={handleFilterChange('profileType')}
                                        checkedItems={filters.profileType}
                                    />
                                    <MultiSelectList
                                        className="sub-filter"
                                        subheaderText={intl.formatMessage({ id: "experience" })}
                                        items={EXPERIENCE_ITEMS}
                                        onChangeHandler={handleFilterChange('experience')}
                                        checkedItems={filters.experience}
                                    />
                                    <div className="text-fields-wrapper">
                                        <div className="project-about-technologies-block">
                                            <p className="list-sub-header">{intl.formatMessage({ id: "maximum-salary" })}</p>
                                            <div className="text-field-control">
                                                <TextField
                                                    name="maximumSalary"
                                                    value={maximumSalaryValue}
                                                    onChange={(event) => setMaximumSalaryValue(event.target.value)}
                                                    label={intl.formatMessage({ id: "maximum-salary" })}
                                                    variant="outlined"
                                                    fullWidth
                                                    className="text-field"
                                                    type="text"
                                                />
                                                <PrimaryButton className="dark" handleClick={() => handleFilterChange('maximumSalary')(maximumSalaryValue)} text="Apply" />
                                            </div>
                                        </div>
                                        <div className="project-about-technologies-block">
                                            <p className="list-sub-header">{intl.formatMessage({ id: "language" })}</p>
                                            <div className="text-field-control">
                                                <TextField
                                                    name="languages"
                                                    value={language}
                                                    onChange={(event) => setLanguage(event.target.value)}
                                                    label={intl.formatMessage({ id: "language" })}
                                                    variant="outlined"
                                                    fullWidth
                                                    className="text-field"
                                                    type="text"
                                                />
                                                <PrimaryButton className="dark" handleClick={() => handleFilterChange('languages')(language)} text="Apply" />
                                            </div>
                                        </div>
                                        <div className="project-about-technologies-block">
                                            <p className="list-sub-header">{intl.formatMessage({ id: "location" })}</p>
                                            <div className="text-field-control">
                                                <TextField
                                                    name="location"
                                                    value={location}
                                                    onChange={(event) => setLocation(event.target.value)}
                                                    label={intl.formatMessage({ id: "location" })}
                                                    variant="outlined"
                                                    fullWidth
                                                    className="text-field"
                                                    type="text"
                                                />
                                                <PrimaryButton className="dark" handleClick={() => handleFilterChange('location')(location)} text="Apply" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div className="mandatory-nice-to-have-container">
                                <div className="project-about-technologies-block">
                                    <p className="list-sub-header">{intl.formatMessage({ id: "mandatory-skills" })}</p>
                                    <CategorySkills
                                        handleSkillDrag={() => void 0}
                                        skillCategory={SKILLS_CATEGORY.MANDATORY}
                                        skills={filters.mandatorySkills}
                                        fetchSkillSuggestions={fetchSkillSuggestionsHandler}
                                        removeSkill={handleRemoveSkill('mandatorySkills')}
                                        addSkill={handleAddSkill('mandatorySkills')}
                                    />
                                </div>
                                <div className="project-about-technologies-block">
                                    <p className="list-sub-header">{intl.formatMessage({ id: "preferred-tech-stack" })}</p>
                                    <CategorySkills
                                        handleSkillDrag={() => void 0}
                                        skillCategory={SKILLS_CATEGORY.PREFERRED_STACK}
                                        skills={filters.preferredTechStack}
                                        fetchSkillSuggestions={fetchSkillSuggestionsHandler}
                                        removeSkill={handleRemoveSkill('preferredTechStack')}
                                        addSkill={handleAddSkill('preferredTechStack')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="collapse-filters-icon-container">
                        <KeyboardArrowUpIcon
                            className="collapse-filters-icon"
                            onClick={() => setShowFilters((oldState) => !oldState)}
                        />
                    </div>
                </div>
            </Accordion>
        </div>
    );
};

const mapStateToProps = (state) => ({
    profileTypes: getProfileTypes(state),
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    fetchProfileTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreTalentsSearchFilter);
