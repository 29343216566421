import skillsService from 'services/skillsService';

import { authorizedShowNotification } from './actionHelpers';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { SKILLS_MESSAGES } from 'constants/messageConstants';

import { CREATE_SKILL_SUCCESS, DELETE_SKILL_SUCCESS, EDIT_SKILL_SUCCESS, FETCH_SKILLS_SUCCESS } from 'actions/actionTypes';
import { showNotification } from './notificationActions';

const fetchSkillsSuccess = data => ({
    type: FETCH_SKILLS_SUCCESS,
    payload: data
});

const createSkillSuccess = data => ({
    type: CREATE_SKILL_SUCCESS,
    payload: data,
});

const editSkillSuccess = data => ({
    type: EDIT_SKILL_SUCCESS,
    payload: data,
});

const deleteSkillSuccess = data => ({
    type: DELETE_SKILL_SUCCESS,
    payload: data,
});

export const fetchSkills = (agencyId) => async dispatch => {
    try {
        const result = await skillsService.getSkills(agencyId);

        dispatch(fetchSkillsSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SKILLS_MESSAGES.FETCH_SKILLS_FAILED, NOTIFICATION_TYPES.ERROR));
    }
};

export const createSkill = (agencyId, data) => async dispatch => {
    try {
        const result = await skillsService.createSkill(agencyId, data);

        dispatch(createSkillSuccess(result));
        dispatch(showNotification(SKILLS_MESSAGES.CREATE_SKILL_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SKILLS_MESSAGES.CREATE_SKILL_FAILED, NOTIFICATION_TYPES.ERROR));
    }
};

export const editSkill = (agencyId, skillId, data) => async dispatch => {
    try {
        const result = await skillsService.editSkill(agencyId, skillId, data);

        dispatch(editSkillSuccess(result));
        dispatch(showNotification(SKILLS_MESSAGES.EDIT_SKILL_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SKILLS_MESSAGES.EDIT_SKILL_FAILED, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteSkill = (agencyId, skillId) => async dispatch => {
    try {
        const result = await skillsService.deleteSkill(agencyId, skillId);

        console.log(result);

        dispatch(deleteSkillSuccess(result));
        dispatch(showNotification(SKILLS_MESSAGES.DELETE_SKILL_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(SKILLS_MESSAGES.DELETE_SKILL_FAILED, NOTIFICATION_TYPES.ERROR));
    }
};
