import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchJobSearch, fetchJobSearchCandidateInterview, likeCandidate, markViewedCandidate, unlikeCandidate } from 'actions/jobSearchActions';
import { fetchProfile } from 'actions/employeeActions';
import { exportCandidateCV } from 'actions/candidateActions';
import { createInterviewInvitation } from 'actions/interviewInvitationActions';

import { getAgencyId, getCandidatePreferences, getClientId, getDisplayMode, getEmployeeCvInformation, getInterviewInvitationDetails, getJobSearch, getUserId } from 'reducers';

import { Avatar, CircularProgress } from '@material-ui/core';

import PaperCv from 'components/Shared/PaperCv';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import JobSearchResultsEmpty from './JobSearchResultsEmpty';
import InterviewInvitePopup from 'components/Shared/InterviewInvitePopup';

import { getStateAgencyName, parseQueryString } from 'utils/helpers';
import { getShouldBlurCVInformation } from 'utils/jobSearchesUtils';

import defaultAvatar from 'assets/default-avatar.svg';

import { ReactComponent as RightArrow } from 'assets/arrow-right.svg';
import { ReactComponent as HeartIcon } from 'assets/client-menu-saved-talents.svg';
import { ReactComponent as OvalPreviousBtn } from 'assets/oval-previous-button.svg';
import { ReactComponent as OvalNextBtn } from 'assets/oval-next-button.svg';

import { ROLES } from 'constants/userConstants';
import { INTERVIEW_INVITATION_CONTRIBUTORS, INTERVIEW_INVITATION_STATUSES } from 'constants/interviewInvitationConstants';

import './JobSearchResults.scss';

const JobSearchResults = ({
    history,
    match,
    location,
    agencyId,
    clientId,
    userId,
    isAdmin,
    jobSearch,
    cvInformation,
    preferencesTechnologies,
    fetchJobSearch,
    fetchProfile,
    exportCandidateCV,
    likeCandidate,
    unlikeCandidate,
    fetchJobSearchCandidateInterview,
    interviewInvitation,
    createInterviewInvitation,
    markViewedCandidate,
}) => {
    const pages = Array(!jobSearch.matchingCandidatesCount ? 0 : jobSearch.matchingCandidatesCount < 10 ? jobSearch.matchingCandidatesCount : 10).fill(null);

    const [isJobSearchFetched, setIsJobSearchFetched] = useState(false);
    const [isProfileFetched, setIsProfileFetched] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [jobSearchClientId, setJobSearchClientId] = useState(clientId);

    useEffect(() => {
        const jobSearchId = match.params.id;
        let clientId = jobSearchClientId;

        if (history.location.state?.clientId) {
            clientId = history.location.state?.clientId;
            setJobSearchClientId(clientId);
        }

        fetchJobSearch(agencyId, clientId, jobSearchId).then(() => setIsJobSearchFetched(true));
    }, []);

    useEffect(() => {
        const jobSearchId = match.params.id;
        const { candidateId } = parseQueryString(location.search);

        if (isJobSearchFetched && jobSearch.matchingCandidates && jobSearch.matchingCandidates[currentPage]) {
            setIsProfileFetched(false);
            fetchProfile(jobSearch.matchingCandidates[currentPage].candidateId, agencyId).then(() => {
                fetchJobSearchCandidateInterview(agencyId, jobSearchClientId, jobSearchId, jobSearch.matchingCandidates[currentPage].candidateId).then(() => setIsProfileFetched(true));
                markViewedCandidate(agencyId, jobSearchClientId, jobSearchId, jobSearch.matchingCandidates[currentPage].candidateId);
            })
        }

        if (isJobSearchFetched && candidateId) {
            const candidateIndex = jobSearch.matchingCandidates.findIndex(x => x.candidateId === candidateId);

            if (candidateIndex !== -1) {
                setCurrentPage(candidateIndex);
                history.replace(location.pathname);
            }
        };
    }, [isJobSearchFetched, currentPage]);

    useEffect(() => {
        const { candidateId } = parseQueryString(location.search);

        if (isJobSearchFetched && !candidateId) {
            let currentIndex = currentPage;
    
            for (const idx in jobSearch.matchingCandidates) {
                if (!jobSearch.matchingCandidates[idx].isVisited) {
                    currentIndex = +idx;
                    break;
                }
            };

            setCurrentPage(currentIndex);
        }
    }, [isJobSearchFetched]);

    const handleExportCv = () => {
        const user = {
            _id: jobSearch.matchingCandidates[currentPage].candidateId,
            firstName: cvInformation.profileInformation.firstName,
            lastName: cvInformation.profileInformation.lastName
        };

        exportCandidateCV([user], agencyId);
    };


    const handleLikeClick = () => {
        const candidateId = jobSearch.matchingCandidates[currentPage].candidateId;

        likeCandidate(agencyId, jobSearchClientId, jobSearch._id, candidateId);
    };

    const handleUnlikeClick = () => {
        const candidateId = jobSearch.matchingCandidates[currentPage].candidateId;

        unlikeCandidate(agencyId, jobSearchClientId, jobSearch._id, candidateId);
    };

    const handleInviteToInterview = (values) => {
        const { clientNote, interviewTimeSlots, ...restData } = values;
        const interviewer = isAdmin ? jobSearchClientId : userId;
        const candidateId = jobSearch.matchingCandidates[currentPage].candidateId;

        const additionalData = {
            dateAdded: moment.utc().format(),
            status: INTERVIEW_INVITATION_STATUSES.PENDING,
            proposedBy: INTERVIEW_INVITATION_CONTRIBUTORS.INTERVIEWER,
            interviewer: interviewer,
            participant: candidateId,
            jobSearchId: match.params.id,
            interviewTimeSlots: interviewTimeSlots.map(slot => {
                const date = slot.date.toISOString().split("T")[0];
                const time = slot.time.toISOString().split("T")[1];

                return {
                    date: `${date}T${time}`,
                    time: `${date}T${time}`
                }
            })
        };

        createInterviewInvitation(agencyId, { ...restData, ...additionalData, note: clientNote });
    };

    const handleGoToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleGoToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleGoToJobSearchDetails = () => {
        history.push({
            pathname: `/${getStateAgencyName()}/talent-searches/${jobSearch._id}`,
            state: { clientId: jobSearch.clientId },
        })
    };

    const getIsCurrentCandidateLiked = () => {
        return jobSearch.likedCandidates?.includes(jobSearch.matchingCandidates[currentPage].candidateId);
    }

    return (
        <div className="job-search-results-wrapper">
            {!isJobSearchFetched ? <CircularProgress className="page-loader deep-orange" size={50} /> :
                <Fragment>
                    <div className="previous-button-wrapper">
                        {currentPage > 0 &&
                            <OvalPreviousBtn className="previous-button" onClick={handleGoToPreviousPage} />
                        }
                    </div>
                    <div className="job-search-results-container">
                        <div className="job-search-pages-wrapper">
                            {pages.map((_, idx) => (
                                <span className={`job-search-pages-page ${idx === currentPage ? 'selected' : ''}`}></span>
                            ))}
                        </div>
                        <div className="job-search-results-position-info-container">
                            <div className="job-search-results-position-info-content">
                                <Avatar className="job-search-results-image" src={jobSearch.imageData?.jobImgUrl || defaultAvatar} />
                                <div className="job-searches-job-card-header-text">
                                    <p className="subheading-xs color-grey">Position:</p>
                                    <p className="heading-xs color-dark-grey">{jobSearch.position}</p>
                                </div>
                            </div>
                            <div className="job-search-position-info-action">
                                <p className="subheading-l color-deep-orange" onClick={handleGoToJobSearchDetails}>View details</p>
                                <RightArrow />
                            </div>
                        </div>
                        {jobSearch.matchingCandidates?.length > 0 ?
                            <PaperCv
                                cvInformation={{...cvInformation, preferredStack: preferencesTechnologies}}
                                handleExportCV={handleExportCv}
                                enableEdit={false}
                                isLoading={!isProfileFetched}
                                loadingClassName="deep-orange page-loader"
                                blurCVInformation={getShouldBlurCVInformation(isAdmin, jobSearch._id, cvInformation.acceptedInterviews)}
                            >
                                <PaperCv.ActionButtons />
                                <PaperCv.ProfileInformation matchingScore={isJobSearchFetched ? jobSearch.matchingCandidates[currentPage].matchingScore : null} />
                                <PaperCv.ProfileSummary />
                                <PaperCv.Skills />
                                <PaperCv.Education />
                                <PaperCv.Experience />
                                <PaperCv.Certificates />
                                <PaperCv.Languages />
                            </PaperCv> :
                            <JobSearchResultsEmpty
                                title="That's all it folks!"
                                subtitle="Looks like we have ran out of candidates to show you, get in touch with our recruiters directly and we can start recruiting just for you!"
                                actionBtnText="Get in touch"
                                actionBtnClickHandler={() => alert("TODO!")}
                            />
                        }
                        {isProfileFetched && jobSearch.matchingCandidates?.length > 0 &&
                            <div className="job-search-results-actions-wrapper">
                                <SecondaryButton className="white" icon={<HeartIcon className={getIsCurrentCandidateLiked() ? "filled" : "white"} />} text="Like" handleClick={getIsCurrentCandidateLiked() ? handleUnlikeClick : handleLikeClick} />
                                <PrimaryButton disabled={!!interviewInvitation._id} className={`dark ${!!interviewInvitation._id ? 'disabled' : ''}`} text="Invite to interview" handleClick={() => setIsInviteDialogOpen(true)} />
                            </div>
                        }

                        <InterviewInvitePopup
                            displayMode={ROLES.CLIENT}
                            dialogTitle="Invitation for interview"
                            isWithNote={true}
                            isDialogOpen={isInviteDialogOpen}
                            isLocationDisabled={false}
                            handleCloseDialog={() => setIsInviteDialogOpen(false)}
                            handelInterviewInvitationSubmit={handleInviteToInterview}
                        />
                    </div>
                    <div className="next-button-wrapper">
                        {currentPage < jobSearch.matchingCandidates?.length - 1 &&
                            <OvalNextBtn className="next-button" onClick={handleGoToNextPage} />
                        }
                    </div>
                </Fragment>
            }
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    clientId: getClientId(state),
    isAdmin: getDisplayMode(state) === ROLES.ADMIN,
    userId: getUserId(state),
    jobSearch: getJobSearch(state),
    cvInformation: getEmployeeCvInformation(state),
    preferencesTechnologies: getCandidatePreferences(state).technologies,
    interviewInvitation: getInterviewInvitationDetails(state),
})

const mapDispatchToProps = {
    fetchJobSearch,
    fetchProfile,
    exportCandidateCV,
    likeCandidate,
    unlikeCandidate,
    fetchJobSearchCandidateInterview,
    createInterviewInvitation,
    markViewedCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(JobSearchResults);
