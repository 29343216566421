import React, { Fragment } from 'react';

import { Rating } from '@material-ui/lab';

import { ReactComponent as FilledStar } from 'assets/icons-star-filled.svg';
import { ReactComponent as EmptyStar } from 'assets/icons-star.svg';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';

import './SortableSkillList.scss';

const SortableSkillItem = ({
    item,
    removeItem,
    rateItem,
    hideRating
}) => (
    <Fragment>
        <div className="skill-box-row">
            <span className="skill-name subheading-l color-dark-grey">{item.title || item.name}</span>
            <div className="editable-container">
                { !hideRating &&
                    <Rating
                        name={item._id}
                        id={`${item._id}`}
                        value={item.rating || 0}
                        icon={<FilledStar />}
                        emptyIcon={<EmptyStar />}
                        className="rating-stars-wrapper"
                        onChange={(e) => {
                            rateItem(e.target.value);
                        }}
                    />
                }
                <button className="delete-skill-icon" type="button" onClick={removeItem}><CloseIcon /></button>
            </div>
        </div>
        <hr className="custom-divider" />
    </Fragment>
);

const SortableSkillList = ({ items, onRemove, onRate, skillList, hideRating }) => (
    <div className="skills-wrapper">
        <div className="skills-container">
            {items.map((value, index) => {
                const itemIndex = skillList.findIndex(x => x._id === value._id);

                return (
                    <SortableSkillItem
                        key={`item-${index}`}
                        index={index}
                        removeItem={() => onRemove(itemIndex)}
                        rateItem={(newRating) => onRate(itemIndex, newRating)}
                        item={value}
                        hideRating={hideRating}
                    />
                )
            })}
        </div>
    </div>
);

export default SortableSkillList;
