import preferredStackGif from 'assets/gifs/preferred_stack.gif';
import programmingLanguagesGif from 'assets/gifs/programming_languages.gif';
import frameworksAndLibrariesGif from 'assets/gifs/frameworks_and_libraries.gif';
import databasesGif from 'assets/gifs/databases.gif';
import cloudsGif from 'assets/gifs/clouds.gif';
import toolsGif from 'assets/gifs/tools.gif';
import otherGif from 'assets/gifs/other.gif';

export const TOOLTIP_TEXT = "Please complete your technical self-assessment to move forward in the hiring process. Be as honest as possible, as the skills and knowledge listed will be further evaluated during interviews.";

export const PREFERRED_STACK_KEY = "preferredStack";

export const SELF_ASSESSMENT_MENU_ITEMS_KEYS = {
    PREFERRED_STACK: PREFERRED_STACK_KEY,
    PROGRAMMING_LANGUAGES: "programmingLanguages",
    FRAMEWORKS_AND_LIBRARIES: "frameworksAndLibraries",
    DATABASES: "databases",
    CLOUDS: "commercialClouds",
    TOOLS: "tools",
    OTHER: "other",
};

export const SELF_ASSESSMENT_MENU_ITEMS = [
    { title: "Preferred Stack", key: PREFERRED_STACK_KEY, gifSource: preferredStackGif },
    { title: "Programming Languages", key: SELF_ASSESSMENT_MENU_ITEMS_KEYS.PROGRAMMING_LANGUAGES, gifSource: programmingLanguagesGif },
    { title: "Frameworks & Libraries", key: SELF_ASSESSMENT_MENU_ITEMS_KEYS.FRAMEWORKS_AND_LIBRARIES, gifSource: frameworksAndLibrariesGif },
    { title: "Databases", key: SELF_ASSESSMENT_MENU_ITEMS_KEYS.DATABASES, gifSource: databasesGif },
    { title: "Clouds", key: SELF_ASSESSMENT_MENU_ITEMS_KEYS.CLOUDS, gifSource: cloudsGif },
    { title: "Tools", key: SELF_ASSESSMENT_MENU_ITEMS_KEYS.TOOLS, gifSource: toolsGif },
    { title: "Other", key: SELF_ASSESSMENT_MENU_ITEMS_KEYS.OTHER, gifSource: otherGif },
];
