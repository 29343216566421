import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Rating } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { getAgencyId, getDisplayMode, getSkillSuggestions } from 'reducers';

import { fetchSkillSuggestions, setSkillSuggestionsInitialState } from 'actions/employeeActions';

import { PREFERRED_STACK_KEY, SELF_ASSESSMENT_MENU_ITEMS_KEYS } from 'components/TechnicalSelfAssessment/TechnicalSelfAssessmentConstants';

import { useDebounce } from 'utils/hooks';

import { ReactComponent as FilledStar } from 'assets/icons-star-filled.svg';
import { ReactComponent as EmptyStar } from 'assets/icons-star.svg';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';

import './SelectTechStack.scss';

const SelectTechStack = ({
    skillsCategory,
    label,
    agencyId,
    handleInputChange,
    handleOnDeleteSkill,
    error,
    technologies,
    existingSkills,
    skillSuggestions,
    fetchSkillSuggestions,
    setSkillSuggestionsInitialState,
    setFieldValue,
    values,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [reset, setReset] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [isSkillSuggestionsFetching, setIsSkillSuggestionsFetching] = useState(false);

    const debouncedInputValue = useDebounce(inputValue, 500);

    useEffect(() => {
        if (debouncedInputValue) {
            fetchSkillSuggestions(agencyId, debouncedInputValue)
                .then(() => setIsSkillSuggestionsFetching(false));
        }
    }, [debouncedInputValue]);

    const handleArrowClick = () => setOpenDropdown(prevState => !prevState);

    const resetInput = () => {
        setInputValue('');
        setOpenDropdown(false);
        setSkillSuggestionsInitialState();
        // this value is used for the key prop of Autocomplete,
        // changing the value resets the Autocomplete's input
        setReset(prevState => !prevState);
    };

    const handleAddSkill = (value) => {
        const skill = { _id: value?.toLowerCase(), title: value, category: SELF_ASSESSMENT_MENU_ITEMS_KEYS.OTHER, isNew: true };
        console.log(skill);
        handleInputChange(skill);
    };

    const handleRatingChange = (value, skillIndex) => {
        const skillsCopy = [...values.skills];
        skillsCopy[skillIndex].rating = value;

        if (skillsCategory === PREFERRED_STACK_KEY) {
            skillsCopy[skillIndex].title = skillsCopy[skillIndex].name;
            delete skillsCopy[skillIndex].name;
        }

        setFieldValue('skills', skillsCopy);
    };

    return (
        <div className="select-tech-stack-wrapper">
            <Autocomplete
                className="select-tech-stack-autocomplete"
                key={reset}
                disablePortal
                clearOnBlur={false}
                open={!!(openDropdown && inputValue)}
                options={inputValue && skillSuggestions.length > 0 && !skillSuggestions.some(skill => skill._id === inputValue?.toLowerCase()) && skillsCategory !== SELF_ASSESSMENT_MENU_ITEMS_KEYS.PREFERRED_STACK
                    ? [{ _id: inputValue?.toLowerCase(), title: inputValue, isNew: true }, ...skillSuggestions]
                    : skillSuggestions
                }
                loading={isSkillSuggestionsFetching}
                loadingText={
                    <div className="search-skills-loader">
                        <CircularProgress thickness={5} size={20} />
                    </div>
                }
                noOptionsText={inputValue && skillSuggestions.length === 0 && skillsCategory !== SELF_ASSESSMENT_MENU_ITEMS_KEYS.PREFERRED_STACK
                    ? <div className="add-new-skill-option" onMouseDown={() => handleAddSkill(inputValue)}>{`Add skill - "${inputValue}"`}</div>
                    : 'No options'
                }
                popupIcon={
                    <Icon>
                        <KeyboardArrowDownIcon onClick={handleArrowClick} />
                    </Icon>
                }
                getOptionLabel={(option) => option.title || ''}
                getOptionSelected={(option, value) => option.title === value.title}
                renderInput={(params) => {
                    return (
                        <>
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                label={label}
                                error={!!error}
                                onChange={(event) => {
                                    setInputValue(event.target.value);
                                    setOpenDropdown(true);
                                    setIsSkillSuggestionsFetching(true);
                                }}
                            />
                            {error && <p className="invalid-field-message">{error}</p>}
                        </>
                    )
                }}
                renderOption={(props) => {
                    const title = props._id === inputValue?.toLowerCase() ? `Add skill - "${inputValue}"` : props.title;
                    const className = props._id === inputValue?.toLowerCase() ? 'add-skill-option' : '';
                    return <div className={className}>{title}</div>
                }}
                onChange={(e, value) => {  
                    resetInput();
                    handleInputChange(value);
                }}
                onClose={(e, reason) => {
                    if (reason === 'select-option') {
                        setInputValue('');
                        setOpenDropdown(false);
                        setSkillSuggestionsInitialState();
                    } else if (reason === 'blur') {
                        setOpenDropdown(false);
                    }
                }}
                onFocus={() => setOpenDropdown(true)}
            />

            {technologies?.length > 0 &&
                <div className="technologies-wrapper">
                    {technologies.map((technology, index) => (
                        <div className="technology-wrapper">
                            <span className="subheading-m color-grey skill-box">{technology.name || technology.title || technology}</span>
                            <div className="technology-right-aligned-items">
                                {skillsCategory &&
                                    <Rating name={technology._id}
                                        id={`${technology._id}_${index}`}
                                        value={technology.rating || 0}
                                        className="rating-stars-wrapper"
                                        icon={<FilledStar />}
                                        emptyIcon={<EmptyStar />}
                                        onChange={(e) => handleRatingChange(e.target.value, index)}
                                    />
                                }
                                <CloseIcon className="delete-technology-icon" onClick={handleOnDeleteSkill.bind(null, index)} />
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    skillSuggestions: getSkillSuggestions(state),
    displayMode: getDisplayMode(state),
});

const mapDispatchToProps = {
    fetchSkillSuggestions,
    setSkillSuggestionsInitialState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectTechStack);
