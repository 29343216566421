import React from 'react';

import { Paper, TextField } from '@material-ui/core';
import { ErrorMessage, Formik } from 'formik';

import { Autocomplete } from '@material-ui/lab';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { validateRequiredFields } from 'utils/formValidations';

import { CLIENT_JOURNEY_STEPS } from 'components/ClientProfileJourney/ClientProfileJourneyConstants';

import '../../ClientProfileJourney.scss';
import { SUPPORTED_COUNTRIES_LIST } from 'constants/candidateConstants';

const CompanyDetails = ({
    companyDetails,
    handleGoToNextStep,
}) => {
    return (
        <Paper className="step-component">
            <p className="heading-m color-dark-grey">Company Details</p>
            <Formik
                initialValues={{
                    name: '',
                    companyWebsite: companyDetails?.companyWebsite || '',
                    hqLocation: companyDetails?.hqLocation || '',
                    country: companyDetails?.country || '',
                    postCode: companyDetails?.postCode || '',
                }}
                validate={(values) => ({ ...validateRequiredFields(values, ['name', 'companyWebsite', 'country', 'postCode']) })}
                onSubmit={async (values) => {
                    const data = {
                        stepId: CLIENT_JOURNEY_STEPS[1]._id,
                        values,
                    };

                    await handleGoToNextStep(data);
                }}
                enableReinitialize={true}
            >
                {({ values, handleChange, handleSubmit, touched, handleBlur, errors, setFieldValue }) => (
                    <form className="step-component-form">
                        <div className="form-control">
                            <p className="subheading-xl color-gray">Which company do you work for?</p>
                            <TextField
                                name="name"
                                label="Company name"
                                value={values.name}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            />
                            <ErrorMessage name="name" component="div" className="invalid-field-message" />
                        </div>
                        <div className="form-control">
                            <p className="subheading-xl color-gray">What's your company website?</p>
                            <TextField
                                name="companyWebsite"
                                label="Website"
                                value={values.companyWebsite}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            />
                            <ErrorMessage name="companyWebsite" component="div" className="invalid-field-message" />
                        </div>
                        <div className="form-control">
                            <p className="subheading-xl color-gray">What's the address of the company?</p>
                            <TextField
                                name="hqLocation"
                                label="Address (street, number)"
                                value={values.hqLocation}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                        <div className="form-control-group">
                            <div className="form-control">
                                <Autocomplete
                                    name="country"
                                    value={values.country}
                                    options={SUPPORTED_COUNTRIES_LIST}
                                    autoHighlight
                                    getOptionLabel={(option) => option}
                                    renderOption={(option) => <span>{option}</span>}
                                    onChange={(e, newValue) => setFieldValue('country', newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={`${touched.country && errors.country ? "with-error" : "base-input"}`}
                                            value={values.country}
                                            onBlur={handleBlur}
                                            label="Country"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                            error={touched.country && Boolean(errors.country)}
                                            helperText={touched.country && errors.country}
                                        />
                                    )}
                                />
                            </div>
                            <div className="form-control">
                                <TextField
                                    name="postCode"
                                    label="Post code"
                                    value={values.postCode}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                                <ErrorMessage name="postCode" component="div" className="invalid-field-message" />
                            </div>
                        </div>
                        <PrimaryButton text="Next" className="dark centered" handleClick={handleSubmit} />
                    </form>
                )}
            </Formik>
        </Paper >
    )
};

export default CompanyDetails;
