import api from './api';
import requester from './requester';

const skillsService = {
    getSkills: (agencyId) => requester(api.skills(agencyId)).getMany(),
    createSkill: (agencyId, data) => requester(api.skills(agencyId)).create(data),
    editSkill: (agencyId, skillId, data) => requester(api.skill(agencyId, skillId)).update(data),
    deleteSkill: (agencyId, skillId) => requester(api.skill(agencyId, skillId)).delete(),
};

export default skillsService;
