import React from 'react';

import { Rating } from '@material-ui/lab';

import { ReactComponent as EditCVIcon } from 'assets/edit-candidate-cv-icon.svg';
import { ReactComponent as FilledStar } from 'assets/icons-star-filled.svg';
import { ReactComponent as EmptyStar } from 'assets/icons-star.svg';

const CvSkillsSection = ({
    enableEdit,
    enterEditMode,
    skills,
    isClient,
    sectionTitle,
    sectionKey,
    image,
}) => (
    <div className="cv-skills-section">
        <div className="header">
            <h1 className="title subheading-xxl color-dark-grey">
                {image && <img src={image} />} {sectionTitle}
            </h1>
            {enableEdit && skills.length > 0 &&
                <div className="item-edit-icon-wrapper" onClick={() => enterEditMode(sectionKey)}>
                    <EditCVIcon />
                </div>
            }
            {enableEdit && skills.length === 0 &&
                <div className="item-edit-icon-wrapper" onClick={() => enterEditMode(sectionKey)}>
                    <p className="subheading-l color-deep-purple">Add Skills</p>
                </div>
            }
        </div>
        {skills?.length > 0
            ?
            <div className="skills-container">
                {skills.map((skill, idx) =>
                    <div className={`skill-box subheading-m color-grey ${isClient ? 'client' : ''}`} key={`${idx}-${skill._id}`}>{skill.name} {skill.rating ? `| ` : ""} {skill.rating &&
                        <Rating
                            name={skill._id}
                            id={`${skill._id}_${idx}`}
                            value={skill.rating || 0}
                            icon={<FilledStar />}
                            emptyIcon={<EmptyStar />}
                            readOnly={true}
                            className="rating-stars-wrapper"
                        />
                    }
                    </div>
                )}
            </div>
            : <div className="empty-category" />
        }
    </div>
);

export default CvSkillsSection;
