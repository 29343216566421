import React from 'react';
import { TextField } from '@material-ui/core';

import './ExploreTalentsSearchBar.scss';

import { ReactComponent as CloseIcon } from 'assets/icons-x.svg';

const ExploreTalentsSearchBar = ({ searchTerm, handleChange, clearInput, searchByTerm }) => {
    return (
        <div className="explore-talents-search-bar-wrapper">
            <div className="secondary-wrapper">
                <TextField
                    id="explore-talents-search-bar"
                    className="talent-search-bar"
                    placeholder={`Search by ${searchByTerm}`}
                    variant="outlined"
                    autoComplete="off"
                    value={searchTerm}
                    onChange={handleChange}
                />
                <div className="skill-box global-css-skill-box">
                    <CloseIcon className="close-icon" onClick={clearInput} />
                </div>
            </div>
        </div>
    );
};

export default ExploreTalentsSearchBar;
