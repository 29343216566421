export const TABLE_COLUMNS = [
    { name: 'Skill Name', value: 'title', isSortable: false, langKey: "title" },
    { name: 'Category', value: 'categorySanitized', isSortable: false, langKey: "category" },
    { name: 'Is Blacklisted', value: 'isBlacklistedSanitized', isSortable: false, langKey: "is-blacklisted" },
];

export const DEFAULT_TABLE_COLUMNS = [
    "title",
    "categorySanitized",
    "isBlacklistedSanitized"
];
