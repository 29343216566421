import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getSkills } from 'reducers';
import { createSkill, deleteSkill, editSkill, fetchSkills } from 'actions/skillsActions';

import { InputAdornment, InputBase } from '@material-ui/core';

import TableWrapper from 'components/Shared/TableWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import DatabaseSkillDialog from './DatabaseSkillDialog';

import { DEFAULT_TABLE_COLUMNS, TABLE_COLUMNS } from './DatabaseSkillsConstants';

import { ReactComponent as SearchIcon } from 'assets/icons-search.svg';

import './DatabaseSkills.scss';
import Pagination from 'components/Shared/Pagination';

const ITEMS_PER_PAGE = 25;

const DatabaseSkills = ({
    agencyId,
    fetchSkills,
    deleteSkill,
    editSkill,
    createSkill,
}) => {
    const intl = useIntl();

    const [selectedSkill, setSelectedSkill] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isDatabaseSkillDialogOpen, setIsDatabaseSkillDialogOpen] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (isDataLoading) {
            fetchSkills(agencyId).then(() => setIsDataLoading(false));
        }
    }, []);

    const dataSelector = (state) => getSkills(state, searchText, page, ITEMS_PER_PAGE).data;
    const skillsCount = useSelector((state) => getSkills(state, searchText, page, ITEMS_PER_PAGE).totalCount);
    const totalPages = Math.ceil(skillsCount / ITEMS_PER_PAGE) === 0 ? 1 : Math.ceil(skillsCount / ITEMS_PER_PAGE);

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
        setPage(1);
    };

    const handleEditClick = (skill) => {
        setSelectedSkill(skill);
        setIsEditMode(true);
        setIsDatabaseSkillDialogOpen(true);
    };

    const handleDeleteClick = (skill) => {
        setSelectedSkill(skill);
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setIsEditMode(false);
        setSelectedSkill(null);
    };

    const handleOpenDatabaseSkillDialog = () => setIsDatabaseSkillDialogOpen(true);
    const handleCloseDatabaseSkillDialog = () => {
        setIsDatabaseSkillDialogOpen(false);
        setIsEditMode(false);
        setSelectedSkill(null);
    };

    const adjustKeys = skills => skills.map(skill => ({
        _id: skill._id,
        title: skill.title,
        category: skill.category,
        isBlacklisted: skill.isBlacklisted,
        isBlacklistedSanitized: skill.isBlacklisted ? "Yes" : "No",
        categorySanitized: skill.category.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, s => s.toUpperCase()),
        item: skill,
    }));

    const getPopoverActions = () => ([
        { title: intl.formatMessage({ id: "edit-skill" }), handleClick: handleEditClick },
        { title: intl.formatMessage({ id: "delete-skill" }), handleClick: handleDeleteClick },
    ]);

    return (
        <TableWrapper
            title={intl.formatMessage({ id: "submenu-database-skills" })}
            columns={TABLE_COLUMNS}
            defaultColumns={DEFAULT_TABLE_COLUMNS}
            dataSelector={dataSelector}
            adjustKeysFunction={adjustKeys}
            isTableDataLoading={isDataLoading}
            addMargin
            withActions
            customPaginationComponent={<Pagination page={page} setPage={setPage} totalPages={totalPages} />}
            getActionsHandler={getPopoverActions}
        >
            <TableWrapper.TableAlignedItems>
                <TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.TableHeader />
                    <InputBase
                        className={"search-term-field"}
                        autoComplete="off"
                        name="searchTerm"
                        value={searchText}
                        onChange={handleSearchTextChange}
                        placeholder='Search title or category'
                        startAdornment={(
                            <InputAdornment position="start">
                                <SearchIcon className="search-icon icon" />
                            </InputAdornment>
                        )}
                    />
                </TableWrapper.TableAlignedItems.LeftAligned>
                <TableWrapper.TableAlignedItems.RightAligned>
                    <TableWrapper.TableAlignedItems.TableActionButton text={intl.formatMessage({ id: "add-skill" })} handleClick={handleOpenDatabaseSkillDialog} />
                </TableWrapper.TableAlignedItems.RightAligned>
            </TableWrapper.TableAlignedItems>

            <TableWrapper.TableContent />

            <ConfirmDeleteDialog
                itemToDelete={selectedSkill?.title}
                handleDeleteItem={() => {
                    deleteSkill(agencyId, selectedSkill?._id);
                    handleCloseDeleteDialog();
                }}
                openDialog={isDeleteDialogOpen}
                handleCloseDialog={handleCloseDeleteDialog}
            />

            <DatabaseSkillDialog
                openDialog={isDatabaseSkillDialogOpen}
                isEditMode={isEditMode}
                handleCloseDialog={handleCloseDatabaseSkillDialog}
                handleSaveDialog={(data) => {
                    isEditMode ? editSkill(agencyId, selectedSkill?._id, data) : createSkill(agencyId, data);
                    handleCloseDatabaseSkillDialog();
                }}
                initialValues={selectedSkill}
            />
        </TableWrapper>
    )
};

const mapDispatchToProps = {
    fetchSkills,
    deleteSkill,
    editSkill,
    createSkill,
};

export default connect(null, mapDispatchToProps)(DatabaseSkills);
