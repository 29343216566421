import React, { useContext, createContext, Fragment } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { getDisplayMode } from 'reducers';

import { CircularProgress, Tooltip } from '@material-ui/core';

import CvProfileInformation from './CvProfileInformation';
import CvProfileSummary from './CvProfileSummary';
import CvSkills from './CvSkills';
import CvEducation from './CvEducation';
import CvExperience from './CvExperience';
import CvCertificates from './CvCertificates';
import CvLanguages from './CvLanguages';

import { BASE_APP_TRADEMARK } from 'constants/env';
import { ROLES } from 'constants/userConstants';

import { ReactComponent as ExportCVIcon } from 'assets/export-candidate-cv-icon.svg';
import { ReactComponent as EditCVIcon } from 'assets/edit-candidate-cv-icon.svg';

import './PaperCv.scss';

export const CandidateCvContext = createContext();
const { Provider } = CandidateCvContext;

const PaperCv = ({
    cvInformation,
    companyLogoUrl,
    enterEditMode,
    enableEdit,
    handleExportCV,
    displayMode,
    children,
    scrollToRef,
    isLoading,
    loadingClassName,
    blurCVInformation,
    cvUserRole,
}) => {
    const context = { cvInformation, enterEditMode, enableEdit, displayMode, scrollToRef, handleExportCV, companyLogoUrl, blurCVInformation, cvUserRole };

    return (
        <div className={`cv-completion-page${isMobile ? ' mobile' : ''}`}>
            <div className="content">
                <Provider value={context}>
                    {isLoading ? <CircularProgress className={loadingClassName} /> : children}
                </Provider>
            </div>
        </div>
    )
};

const ProfileInformation = ({ matchingScore }) => {
    const { cvInformation, displayMode, blurCVInformation } = useContext(CandidateCvContext);

    return (
        <CvProfileInformation blurCVInformation={blurCVInformation} matchingScore={matchingScore} iconClassName={displayMode} profileInformation={cvInformation.profileInformation} />
    );
};

const ProfileSummary = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode } = useContext(CandidateCvContext);

    return (
        <CvProfileSummary
            displayMode={displayMode}
            profileSummary={cvInformation.profileSummary}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

const Skills = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode, cvUserRole } = useContext(CandidateCvContext);

    return (
        <CvSkills
            displayMode={displayMode}
            skills={cvInformation.skills}
            preferredStack={cvInformation.preferredStack}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
            cvUserRole={cvUserRole}
        />
    );
};

const Education = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode } = useContext(CandidateCvContext);

    return (
        <CvEducation
            displayMode={displayMode}
            education={cvInformation.education}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

const Experience = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode, blurCVInformation } = useContext(CandidateCvContext);

    return (
        <CvExperience
            displayMode={displayMode}
            projects={cvInformation.projects}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
            blurCVInformation={blurCVInformation}
        />
    );
};

const Certificates = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode, scrollToRef } = useContext(CandidateCvContext);

    return (
        <CvCertificates
            scrollToRef={scrollToRef}
            displayMode={displayMode}
            certificates={cvInformation.certificates}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

const Languages = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode } = useContext(CandidateCvContext);

    return (
        <CvLanguages
            displayMode={displayMode}
            languages={cvInformation.languages}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

const ActionButtons = ({ }) => {
    const { handleExportCV, enterEditMode, enableEdit, displayMode, cvUserRole } = useContext(CandidateCvContext);

    const isAdmin = displayMode === ROLES.ADMIN;
    const isCandidateCV = cvUserRole === ROLES.CANDIDATE;
    const shouldHaveMultipleExportButtons = isAdmin && isCandidateCV;

    return (
        <div className="action-buttons-wrapper">
            {shouldHaveMultipleExportButtons ?
                <Fragment>
                    <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={"Export full CV"}>
                        <ExportCVIcon onClick={handleExportCV.bind(null, true)} />
                    </Tooltip>
                    <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={"Export CV for clients"}>
                        <ExportCVIcon onClick={handleExportCV.bind(null, false)} />
                    </Tooltip>
                </Fragment>
                :
                <ExportCVIcon onClick={handleExportCV.bind(null, false)} />
            }
            {enableEdit && <EditCVIcon onClick={() => enterEditMode("profileSummary")} />}
        </div>
    );
};

const CompanyLogo = ({ }) => {
    const { companyLogoUrl } = useContext(CandidateCvContext);

    return (
        <>
            {companyLogoUrl
                ? <img className={`cv-logo-content-image ${isMobile && 'mobile'}`} src={companyLogoUrl} />
                : BASE_APP_TRADEMARK.LOGO
            }
        </>
    );
};

PaperCv.ProfileInformation = ProfileInformation;
PaperCv.ProfileSummary = ProfileSummary;
PaperCv.Skills = Skills;
PaperCv.Education = Education;
PaperCv.Experience = Experience;
PaperCv.Certificates = Certificates;
PaperCv.Languages = Languages;
PaperCv.ActionButtons = ActionButtons;
PaperCv.CompanyLogo = CompanyLogo;

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps, null)(PaperCv);
