import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

import { Formik } from 'formik';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import SelectTechStack from '../SelectTechStack';

import { validatesArrayLength } from 'utils/formValidations';

import { ReactComponent as InfoIcon } from "assets/info-icon.svg";

const SkillsDialog = ({
    skillsCategory,
    open,
    onClose,
    onSubmit,
    skills,
}) => {
    const intl = useIntl();

    const formikRef = useRef();

    const handleOnInputChangeSkill = (newValue) => {
        formikRef.current.setFieldValue('skills', [...formikRef.current.values.skills, { ...newValue, name: newValue.title, category: newValue.category}]);
    };

    const handleOnDeleteSkill = (index) => {
        formikRef.current.setFieldValue('skills', [...formikRef.current.values.skills.slice(0, index), ...formikRef.current.values.skills.slice(index + 1)]);
    };

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={{
                skills: [],
            }}
            validate={values => {
                return {
                    ...validatesArrayLength(values, 'skills', 1)
                }
            }}
            onSubmit={(values) => {
                const newSkills = values.skills.filter(skill => skills ? !skills.some(x => (x._id || x.name?.toLowerCase()) === skill._id) : true);
                onSubmit(newSkills);
                onClose();
            }}
        >
            {props => (
                <CommonFormikDialog
                    size="m"
                    className="skill-dialog"
                    dialogTitle={intl.formatMessage({ id: "add-skills" })}
                    saveBtnText="save"
                    discardBtnText="discard"
                    open={open}
                    onClose={onClose}
                    onSave={props.handleSubmit}
                >
                    <p className="hint"><InfoIcon/>{intl.formatMessage({ id: 'not-permanent-save-helper-text' })}</p>
                    <form className="skills-dialog-view form" autoComplete="off">
                        <SelectTechStack
                            {...props}
                            skillsCategory={skillsCategory}
                            label={intl.formatMessage({ id: "type-skill-name" })}
                            handleInputChange={handleOnInputChangeSkill}
                            handleOnDeleteSkill={handleOnDeleteSkill}
                            technologies={props.values.skills}
                            existingSkills={skills}
                            error={props.errors.skills && props.touched.skills ? props.errors.skills : ''}
                        />
                    </form>
                </CommonFormikDialog>
            )}
        </Formik>
    );
};

export default SkillsDialog;
