import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';


import { getAgencyId, getAgencyRouteName } from 'reducers';
import { fetchSkillSuggestions } from 'actions/employeeActions';
import { fetchExploreTalentsProfiles, fetchMoreExploreTalentsProfiles } from 'actions/exploreTalentsActions';

import ExploreTalentsContent from './ExploreTalentsContent';
import ExploreTalentsSearchFilter from './ExploreTalentsSearchFilter';

import { useExploreTalentsFilters } from './ExploreTalentsUtils';

import { DEFAULT_PAGE_VALUE } from './ExploreTalentsConstants';

import './ExploreTalents.scss';


const ExploreTalents = ({
    agencyId,
    fetchSkillSuggestions,
    fetchExploreTalentsProfiles,
    fetchMoreExploreTalentsProfiles,
    agencyRouteName,
}) => {
    const [page, setPage] = useState(DEFAULT_PAGE_VALUE);
    const [hasMore, setHasMore] = useState(true);
    const [showFilters, setShowFilters] = useState(false);

    const [
        hasParsedUrl,
        filters,
        searchTerm,
        specialityTerm,
        handleAddSkill,
        handleRemoveSkill,
        handleSwitchChange,
        handleFilterChange,
        handleSearchTermChange,
        resetFilters,
        handleVettedSinceChange,
    ] = useExploreTalentsFilters();

    const fetchSkillSuggestionsHandler = (inputText) => fetchSkillSuggestions(agencyId, inputText);

    useEffect(() => {
        if (hasParsedUrl) {
            fetchExploreTalentsProfiles(agencyId, filters, DEFAULT_PAGE_VALUE)
                .then(() => {
                    setPage(DEFAULT_PAGE_VALUE + 1);
                    setHasMore(true);
                });
        }
    }, [filters]);


    const handleFetchMoreTalents = async (talents, totalTalentsCount) => {
        if (talents.length >= totalTalentsCount) {
            setHasMore(false);
            return;
        }

        await fetchMoreExploreTalentsProfiles(agencyId, filters, page);
        setPage(page => page + 1);
    };

    return (
        <div>
            <ExploreTalentsSearchFilter
                fetchSkillSuggestionsHandler={fetchSkillSuggestionsHandler}
                filters={filters}
                handleAddSkill={handleAddSkill}
                handleFilterChange={handleFilterChange}
                handleRemoveSkill={handleRemoveSkill}
                handleSwitchChange={handleSwitchChange}
                resetFilters={resetFilters}
                handleVettedSinceChange={handleVettedSinceChange}
                handleSearchTermChange={handleSearchTermChange}
                searchTerm={searchTerm}
                specialityTerm={specialityTerm}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                agencyRouteName={agencyRouteName}
            />
            <div className="explore-talent-content-container">
                <ExploreTalentsContent
                    agencyId={agencyId}
                    handleFetchMoreTalents={handleFetchMoreTalents}
                    hasMore={hasMore}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    agencyRouteName: getAgencyRouteName(state),
});

const mapDispatchToProps = {
    fetchSkillSuggestions,
    fetchExploreTalentsProfiles,
    fetchMoreExploreTalentsProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreTalents);
