import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { fetchProfileTypes } from 'actions/profileTypesActions';
import { getAgencyId, getProfileTypes } from 'reducers';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import './JobTypeDialog.scss';

const JobTypeDialog = ({
    agencyId,
    handleSubmit,
    handleChange,
    values,
    onClose,
    open,
    profileTypes,
    fetchProfileTypes,
}) => {
    const intl = useIntl();

    useEffect(() => {
        fetchProfileTypes(agencyId);
    }, []);

    const onFormSubmit = () => {
        handleSubmit();
    };

    return (
        <CommonFormikDialog
            className="job-type-dialog"
            open={open}
            onClose={onClose}
            dialogTitle={intl.formatMessage({ id: "edit-job-type" })}
            saveBtnText="save"
            discardBtnText="discard"
            onSave={onFormSubmit}
        >
            <form className="job-type-dialog-form" autoComplete="off">
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2">
                            <TextField
                                select
                                value={values.jobType}
                                variant="outlined"
                                onChange={handleChange}
                                name="jobType"
                                id="jobType"
                                fullWidth
                            >
                                {profileTypes.map((option) => {
                                    return (
                                        <MenuItem key={option._id} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                    </div>
                </div>
            </form>
        </CommonFormikDialog>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    profileTypes: getProfileTypes(state)
});

const mapDispatchToProps = {
    fetchProfileTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobTypeDialog);
