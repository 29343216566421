import { CREATE_SKILL_SUCCESS, DELETE_SKILL_SUCCESS, EDIT_SKILL_SUCCESS, FETCH_SKILLS_SUCCESS } from 'actions/actionTypes';

const skillsInitialState = [];

const skillsReducer = (state = skillsInitialState, action) => {
    switch (action.type) {
        case FETCH_SKILLS_SUCCESS:
            return [...action.payload];
        case CREATE_SKILL_SUCCESS:
            return [...state, {...action.payload}];
        case EDIT_SKILL_SUCCESS:
            return [...state.map(x => x._id === action.payload._id ? {...action.payload} : x)];
        case DELETE_SKILL_SUCCESS:
            return [...state.filter(x => x._id !== action.payload)];
        default:
            return state;
    }
};

export default skillsReducer;

export const getSkills = (state, searchText, page, count) => {
    const from = (page - 1) * count;
    const to = (page - 1) * count + count;

    if (searchText) {
        const matchesTitle = (x) => x.title.toLowerCase().includes(searchText.toLowerCase());
        const matchesCategory = (x) => x.category.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().includes(searchText.toLowerCase());
        
        return {
            data: state.filter(x => matchesTitle(x) || matchesCategory(x)).slice(from || 0, to || state.length),
            totalCount: state.filter(x => matchesTitle(x) || matchesCategory(x)).length
        };
    }

    return {
        data: state.slice(from || 0, to || state.length),
        totalCount: state.length,
    };
};
