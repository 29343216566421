import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { ErrorMessage } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';

import { ReactComponent as RadioButtonIcon } from 'assets/radio-button.svg';
import { ReactComponent as RadioButtonCheckedIcon } from 'assets/radio-button-checked.svg';

import './SelectionCard.scss';

const SelectionCard = ({
    autocomplete = false,
    title,
    subTitle,
    options,
    label,
    setFieldValue,
    values,
    name,
    errors,
    errorClassName,
    handleChange,
    touched,
    primaryButtonText,
    primaryButtonClickHandler,
    additionalOptionsComponent,
}) => {
    const [inputValue, setInputValue] = useState(values.jobType);
    const [openDropdown, setOpenDropdown] = useState(false);

    return (
        <PaperElement
            classes={['options-card-wrapper', isMobile ? 'max' : 'sm']}
            elevation={0}
        >
            <PaperHeader title={title} />
            <PaperContent classes={["paper-content-flex"]}>
                <Typography className="options-card-sub-title" variant="h4">
                    {subTitle}
                </Typography>

                {autocomplete ?
                    <Autocomplete
                        className="select-tech-stack-autocomplete"
                        disablePortal={false}
                        clearOnBlur={false}
                        value={options.find(option => option.title === values.jobType) || null}
                        isOptionEqualToValue={(option, value) => option.title === value?.title}
                        open={!!(openDropdown && inputValue)}
                        options={inputValue && options.length > 0
                            ? options.filter(skill => skill._id?.includes(inputValue?.replace(" ", "-").toLowerCase()))
                            : options
                        }
                        loading={false}
                        noOptionsText={'No options'}
                        getOptionLabel={(option) => option.title || ''}
                        getOptionSelected={(option, value) => option.title === value?.title}
                        renderInput={(params) => {
                            return (
                                <>
                                    <TextField
                                        {...params}
                                        name={name}
                                        fullWidth
                                        variant="outlined"
                                        label={label}
                                        error={!!errors}
                                        onChange={(event) => {
                                            setInputValue(event.target.value);
                                            setOpenDropdown(true);
                                        }}
                                    />
                                    {errors['jobType'] && <p className="invalid-field-message">{errors['jobType']}</p>}
                                </>
                            )
                        }}
                        renderOption={(props) => {
                            const title = props.title;
                            return <div>{title}</div>
                        }}
                        onChange={(e, value) => {
                            setInputValue(value);
                            setOpenDropdown(false);
                            setFieldValue('jobType', value?.title);
                        }}
                        onClose={(e, reason) => {
                            if (reason === 'select-option') {
                                setInputValue('');
                                setOpenDropdown(false);
                            } else if (reason === 'blur') {
                                setOpenDropdown(false);
                            }
                        }}
                        onFocus={() => setOpenDropdown(true)}
                    />
                    :
                    <RadioGroup
                        value={values[name]}
                        name={name}
                        className={`options-card-labels-wrapper ${(errors[name] && touched[name] ? errorClassName || 'error' : '')}`}
                        onChange={handleChange}
                    >
                        {options.map(option => (
                            <FormControlLabel
                                className={`options-card-label ${values[name] === option.value ? 'selected' : ''}`}
                                key={option.value}
                                value={option.value}
                                control={<Radio disableRipple className="radio-btn" icon={<RadioButtonIcon />} checkedIcon={<RadioButtonCheckedIcon />} />}
                                label={option.title}
                                labelPlacement="start"
                            />
                        ))}
                        <ErrorMessage
                            name={name}
                            component={() => (
                                <div className="invalid-field-wrapper">
                                    <ErrorRoundedIcon className="error-icon" fontSize="small" />
                                    <span>The field is required. Please select one option.</span>
                                </div>
                            )} />
                    </RadioGroup>
                }
                {additionalOptionsComponent ? additionalOptionsComponent : null}
                <PrimaryButton className="purple" text={primaryButtonText} onClick={primaryButtonClickHandler} />
            </PaperContent>
        </PaperElement>
    )
};

export default SelectionCard;
