import React from 'react';

const PaperHeader = ({
        title, 
        classes=[],
        children
    }) => {
        return (
            <div className={`paper-element-heading ${classes.join(" ")}`}>
                <h1 className="heading-m color-dark-grey">{title}</h1>
                {children}
            </div>
        );
    }

export default PaperHeader;
