import React from 'react';

import { ErrorMessage, Formik } from 'formik';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import { validateRequiredFields } from 'utils/formValidations';

import { SELF_ASSESSMENT_MENU_ITEMS } from 'components/TechnicalSelfAssessment/TechnicalSelfAssessmentConstants';

import { ReactComponent as UncheckedIcon } from 'assets/codery-checkbox-unchecked.svg';
import { ReactComponent as CheckedIcon } from 'assets/codery-checkbox-checked.svg';

import './DatabaseSkillDialog.scss';

const DatabaseSkillDialog = ({
    openDialog,
    handleCloseDialog,
    handleSaveDialog,
    isEditMode,
    initialValues,
}) => (
    <Formik
        initialValues={{
            title: isEditMode ? initialValues.title : "",
            category: isEditMode ? initialValues.category : "",
            isBlacklisted: isEditMode ? initialValues.isBlacklisted : false,
        }}
        enableReinitialize={true}
        validate={values => ({
            ...validateRequiredFields(values, ['title', 'category'])
        })}
        onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleSaveDialog(values);
        }}
    >
        {(props) => (
            <Dialog className="database-skill-dialog-wrapper" open={openDialog} onClose={handleCloseDialog} maxWidth={'sm'}>
                <MuiDialogTitle disableTypography className="database-skill-dialog-header">
                    <IconButton onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent className="dialog-content">
                    <Typography variant="subtitle2" className="subheading-xl color-grey-dark dialog-title">
                        {isEditMode ? "Edit Skill" : "Add Skill"}
                    </Typography>

                    <form className="database-skill-form">
                        <div className="skill-field">
                            <TextField
                                name="title"
                                label="Title"
                                value={props.values.title}
                                onChange={props.handleChange}
                                variant="outlined"
                                fullWidth
                                error={props.errors.title && props.touched.title}
                            />
                            <ErrorMessage name="title" component="div" className="invalid-field-message" />
                        </div>
                        <div className="skill-field">
                            <TextField
                                select
                                name="category"
                                label="Category"
                                value={props.values.category}
                                onChange={props.handleChange}
                                variant="outlined"
                                fullWidth
                                error={props.errors.category && props.touched.category}
                            >
                                {SELF_ASSESSMENT_MENU_ITEMS.map((item) => (
                                    <MenuItem key={item.key} value={item.key}>
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <ErrorMessage name="category" component="div" className="invalid-field-message" />
                        </div>
                        <div className="database-skills-checkbox-wrapper">
                            <Checkbox
                                icon={<UncheckedIcon />}
                                checkedIcon={<CheckedIcon />}
                                name="isBlacklisted"
                                checked={props.values.isBlacklisted}
                                onChange={() => props.setFieldValue("isBlacklisted", !props.values.isBlacklisted)}
                            />
                            <p className="subheading-m color-grey">Is Blacklisted</p>
                        </div>
                    </form>

                    <DialogActions className="dialog-actions">
                        <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
                        <SecondaryButton buttonStyle="save" handleClick={props.handleSubmit} text="Save" type="submit" />
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )}
    </Formik>
);

export default DatabaseSkillDialog;
