
import { FETCH_PROFILE_TYPES_SUCCESS } from './actionTypes';
import profileTypesService from 'services/profileTypesService';

const fetchProfileTypesSuccess = (payload) => ({
	type: FETCH_PROFILE_TYPES_SUCCESS,
	payload
});

export const fetchProfileTypes = (agencyId) => async dispatch => {
    const result = await profileTypesService.getProfileTypes(agencyId);
	dispatch(fetchProfileTypesSuccess(result));
};
