import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import defaultAvatar from 'assets/default-avatar.svg';

import { Avatar } from '@material-ui/core';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import Pagination from 'components/Shared/Pagination';

// import { ReactComponent as LikeBoxIcon } from 'assets/like-box.svg';
import { ReactComponent as SalaryIcon } from 'assets/icons-salary.svg';
import { ReactComponent as ExperienceIcon } from 'assets/icons-experiance.svg';

import { getStateAgencyName } from 'utils/helpers';

import styleConstants from 'style-constants.scss';

import './JobSearchSavedCandidates.scss';

const JobSearchSavedCandidates = ({
    likedCandidates,
    openDialogHandler,
    isSavedTalentsPage,
}) => {
    const history = useHistory();

    const ITEMS_PER_PAGE = isSavedTalentsPage
        ? window.innerWidth <= styleConstants.smallDesktopWidth ? 6 : 8
        : window.innerWidth <= styleConstants.smallDesktopWidth ? 3 : 4;

    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(likedCandidates.length / ITEMS_PER_PAGE) === 0 ? 1 : Math.ceil(likedCandidates.length / ITEMS_PER_PAGE);

    const getPagedCandidates = (likedCandidates) => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        return likedCandidates.slice(startIndex, endIndex);
    };

    return (
        <div className="job-search-saved-candidates-wrapper">
            <div className={`job-search-saved-candidates-container ${isSavedTalentsPage ? "full-page" : ""}`}>
                {getPagedCandidates(likedCandidates).map(candidate => (
                    <div className="job-search-saved-candidates-card">
                        <div className="job-search-saved-candidates-card-header">
                            <Avatar src={candidate.imgUrl || defaultAvatar} />
                            {/* <div className="job-search-saved-candidates-matching-score-wrapper">
                                <LikeBoxIcon />
                                <p className="heading-xs color-dark-grey">{candidate.matchingScore}%</p>
                                <p className="subheading-xxs color-grey">Matching Score</p>
                            </div> */}
                        </div>
                        <div className="job-search-saved-candidates-card-content">
                            <div className="job-search-saved-candidates-personal-info">
                                <p className="subheading-xxl color-dark-grey">{candidate.firstName} {candidate.lastName}</p>
                                <p className="subheading-xs color-grey">{candidate.position}</p>
                                <div className="job-search-saved-candidates-preferences-info">
                                    <div className="job-search-saved-candidates-preferences-info-details">
                                        <SalaryIcon />
                                        <p className="subheading-s color-dark-grey">{candidate.salaryExpectations || 'N/A'} {candidate.salaryExpectations && candidate.salaryExpectationsCurrency ? candidate.salaryExpectationsCurrency : ''}</p>
                                    </div>
                                    <div className="job-search-saved-candidates-preferences-info-details">
                                        <ExperienceIcon />
                                        <p className="subheading-s color-dark-grey">{candidate.yearsOfExperience ? candidate.yearsOfExperience.replace("years", "yrs") : 'N/A'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="job-search-saved-candidates-tech-stack-info">
                                {candidate.preferredTechStack.map(x => (
                                    <p className="subheading-s color-dark-grey job-search-saved-candidates-tech-stack-box">{x.title}</p>
                                ))}
                            </div>
                            { isSavedTalentsPage &&
                            <div className="job-search-saved-candidates-position-content">
                                <p className="subheading-xxs color-grey">Position:</p>
                                <p className="subheading-s color-dark-grey">{candidate.jobSearchPosition}</p>
                            </div>
                            }
                        </div>
                        {isSavedTalentsPage ?
                            <PrimaryButton className={`dark invite-to-interview-button`} text="View CV" handleClick={() => history.push(`/${getStateAgencyName()}/talent-searches/${candidate.jobSearchId}/search-results?candidateId=${candidate._id}`)} />
                            :
                            <PrimaryButton disabled={candidate.hasInterviews} className={`dark invite-to-interview-button ${candidate.hasInterviews ? "disabled" : ""}`} text="Invite to interview" handleClick={() => openDialogHandler(candidate._id)} />
                        }
                    </div>
                ))}
            </div>
            <Pagination page={currentPage} totalPages={totalPages} setPage={setCurrentPage} />
        </div>
    )
};

export default JobSearchSavedCandidates;
