import { FETCH_PROFILE_TYPES_SUCCESS } from 'actions/actionTypes';

const initialState = [];

const profileTypesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PROFILE_TYPES_SUCCESS:
        return [...action.payload];
      default:
        return state;
    }
};

export default profileTypesReducer;

export const getProfileTypes = state => state;
