import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import CvSkillsSection from './CvSkillsSection';

import { ROLES } from 'constants/userConstants';
import { PREFERRED_STACK_KEY, SELF_ASSESSMENT_MENU_ITEMS, SELF_ASSESSMENT_MENU_ITEMS_KEYS } from 'components/TechnicalSelfAssessment/TechnicalSelfAssessmentConstants';

import './CvSkills.scss';

const CvSkills = ({
    skills,
    preferredStack,
    enterEditMode,
    enableEdit,
    displayMode,
    cvUserRole,
}) => {
    const intl = useIntl();

    const isClient = displayMode === ROLES.CLIENT;

    const shouldDisplayMultipleSkillSections = cvUserRole === ROLES.CANDIDATE;

    const filterSkills = (skills, key) => skills.filter(x => x.category === key || (key === SELF_ASSESSMENT_MENU_ITEMS_KEYS.OTHER && !x.category) || (key === SELF_ASSESSMENT_MENU_ITEMS_KEYS.PREFERRED_STACK && preferredStack.length > 0));

    const mapPreferredStackToSkills = (stack) => stack.map(x => ({ _id: x._id, name: x.title, category: x.category, rating: x.rating }));

    return (
        <>
            <div className="cv-skills-wrapper">
                { shouldDisplayMultipleSkillSections ? 
                    <div className="cv-skills-container">
                        {SELF_ASSESSMENT_MENU_ITEMS.map((item, idx) => (
                            <Fragment>
                                { (idx === SELF_ASSESSMENT_MENU_ITEMS.length - 1 && isClient) || (isClient && !filterSkills(skills, item.key).length) ? null :  
                                    <CvSkillsSection
                                        sectionTitle={item.title}
                                        skills={item.key === PREFERRED_STACK_KEY ? mapPreferredStackToSkills(preferredStack) : filterSkills(skills, item.key)}
                                        enableEdit={!isClient}
                                        enterEditMode={enterEditMode}
                                        sectionKey={item.key}
                                        isClient={isClient}
                                        image={item.gifSource}
                                    />
                                }
                            </Fragment>
                        ))}
                    </div>
                : <CvSkillsSection 
                    sectionTitle={intl.formatMessage({ id: "skills-text" })}
                    skills={skills}
                    enterEditMode={enterEditMode}
                    enableEdit={enableEdit}
                    sectionKey="skills"
                    isClient={isClient}
                /> }
            </div>
        </>
    )
};

export default CvSkills;
