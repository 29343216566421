import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import moment from 'moment';

import { Avatar, CircularProgress } from '@material-ui/core';

import { deleteJobSearch, updateJobSearchStatus } from 'actions/jobSearchActions';

import { getAgencyId } from 'reducers';

import ThreeDotsOptionsPopover from 'components/Shared/ThreeDotsOptionsPopover';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import JobSearchDropdown from './JobSearchDropdown';

import { getStateAgencyName } from 'utils/helpers';

import { DATE_FORMAT_WITH_SLASH, DATE_FORMAT_WITH_SLASH_SHORT_YEAR } from 'constants/commonConstants';
import { JOB_OPPORTUNITIES_STATUSES } from 'constants/jobOpportunitiesConstants';

import { ReactComponent as SavedTalentsIcon } from 'assets/client-menu-saved-talents.svg';
import { ReactComponent as InterviewsIcon } from 'assets/client-menu-interviews.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/arrow-down.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/three-dots-icon.svg';
import { ReactComponent as RetryIcon } from 'assets/retry-icon.svg';

import defaultAvatar from 'assets/default-avatar.svg';

import './JobSearch.scss';

const JobSearch = ({
    jobSearch,
    isLoadingCandidateSearch,
    jobSearchId,
    isDetailsPage = false,
    updateJobSearchStatus,
    deleteJobSearch,
    agencyId,
    clientId,
    jobStatusChangeCallback,
    isAdmin,
}) => {
    const history = useHistory();

    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onActionClick = () => {
        if (!isDetailsPage) {
            history.push({
                pathname: `/${getStateAgencyName()}/talent-searches/${jobSearch._id}`,
                state: { clientId: jobSearch.clientId }
            });
        } else {
            setIsDetailsOpen(state => !state);
        }
    };

    const refreshSearchHandler = () => history.push({
        pathname: `/${getStateAgencyName()}/talent-searches`,
        search: `?jobSearchId=${jobSearch._id}`,
        state: { clientId: jobSearch.clientId },
    });

    const onThreeDotsClick = (event) => {
        setPopoverAnchorEl(event.target);
        setIsPopoverOpen(true);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
        setIsPopoverOpen(false);
    };

    const getPopoverOptions = (jobSearch) => {
        if (jobSearch.status === JOB_OPPORTUNITIES_STATUSES.OPEN) {
            return [
                { title: "Close", handleClick: () => updateJobSearchStatus(agencyId, jobSearch.clientId, jobSearch._id, JOB_OPPORTUNITIES_STATUSES.CLOSED).then(jobStatusChangeCallback) },
                { title: "Delete", handleClick: () => deleteJobSearch(agencyId, jobSearch.clientId, jobSearch._id).then(() => history.push(`/${getStateAgencyName()}/talent-searches`)) },
            ]
        }

        return [
            { title: "Open", handleClick: () => updateJobSearchStatus(agencyId, jobSearch.clientId, jobSearch._id, JOB_OPPORTUNITIES_STATUSES.OPEN).then(jobStatusChangeCallback) },
            { title: "Delete", handleClick: () => deleteJobSearch(agencyId, jobSearch.clientId, jobSearch._id).then(() => history.push(`/${getStateAgencyName()}/talent-searches`)) },
        ]
    };

    const handleViewCandidatesClick = () => {
        history.push({
            pathname: `/${getStateAgencyName()}/talent-searches/${jobSearch._id}/search-results`,
            state: { clientId: jobSearch.clientId },
        })
    };

    return (
        <div className="job-searches-job">
            <div className="job-searches-job-card">
                <div className="job-searches-job-card-header">
                    <div className="job-searches-job-card-header-info">
                        <Avatar className="job-searches-image" src={jobSearch.imageData?.jobImgUrl || defaultAvatar} />
                        <div className="job-searches-job-card-header-text">
                            <p className="heading-xs color-dark-grey">{jobSearch.position}</p>
                            {isAdmin && <p className="subheading-s color-grey">{jobSearch.clientInfo.name}</p>}
                            <p className="subheading-xs color-grey">{moment(jobSearch.creationDate).format(DATE_FORMAT_WITH_SLASH)}</p>
                        </div>
                    </div>
                    <div className="job-searches-job-card-options">
                        <div className={`job-searches-job-card-header-status ${jobSearch.status}`}>{jobSearch.status}</div>
                        <ThreeDotsIcon onClick={onThreeDotsClick} />

                        {isPopoverOpen &&
                            <ThreeDotsOptionsPopover
                                anchorEl={popoverAnchorEl}
                                listItems={getPopoverOptions(jobSearch)}
                                handleClose={handlePopoverClose}
                                closeOnClick
                            />
                        }
                    </div>
                </div>
                <hr className="custom-divider" />
                <div className="job-searches-job-card-footer">
                    <div className="job-searches-job-card-footer-info">
                        <div className="job-searches-job-card-footer-info-container">
                            <SavedTalentsIcon className="job-searches-job-card-footer-icon" />
                            <p className="subheading-xxl color-grey">{jobSearch.likedCandidates?.length || 0} Saved Candidates</p>
                        </div>
                        <div className="job-searches-job-card-footer-info-container">
                            <InterviewsIcon className="job-searches-job-card-footer-icon" />
                            <p className="subheading-xxl color-grey">{jobSearch.interviewInvitations?.length || 0} {jobSearch.interviewInvitations?.length === 1 ? "Interview" : "Interviews"}</p>
                        </div>
                    </div>
                    <div className="job-searches-job-card-footer-actions" onClick={onActionClick}>
                        <p className="job-searches-job-card-footer-view-details">{isDetailsPage ? isDetailsOpen ? "Less description" : "Full description" : "View details"}</p>
                        {isDetailsPage ? isDetailsOpen ? <ArrowUpIcon className="arrow-up"/> : <ArrowDownIcon className="arrow-down" /> : <ArrowRightIcon />}
                    </div>
                </div>
                {isDetailsOpen &&
                    <JobSearchDropdown jobSearch={jobSearch} />
                }
            </div>
            <div className="job-searches-job-matching-candidates">
                {isLoadingCandidateSearch && jobSearchId === jobSearch._id ?
                    <Fragment>
                        <CircularProgress className="deep-orange" />
                        <p className="subheading-xxl color-dark-grey">Searching Candidates...</p>
                    </Fragment> :
                    <Fragment>
                        {!isDetailsPage &&
                            <div className="job-search-matching-candidates-search-again">
                                <p className="subheading-xs color-gray">{jobSearch.lastChanged ? `Last updated: ${moment(jobSearch.lastChanged).format(DATE_FORMAT_WITH_SLASH_SHORT_YEAR)}` : "Last updated: N/A"}</p>
                                <RetryIcon onClick={refreshSearchHandler} />
                            </div>
                        }
                        <div className="job-searches-job-matching-candidates-container">
                            <p className="heading-m color-deep-orange">{jobSearch.matchingCandidatesCount || 0}</p>
                            <p className="subheading-m color-gray">Matching Candidates</p>
                            <PrimaryButton className="dark" text="View Candidates" handleClick={handleViewCandidatesClick} />
                        </div>
                    </Fragment>
                }
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    updateJobSearchStatus,
    deleteJobSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobSearch);
